import styled from 'styled-components';
import { PriceBoxStyled } from '../PriceBoxStyled';
import { rem } from 'polished';

export const PriceBoxStyledDS = styled(PriceBoxStyled)`
    .priceBoxContainerContentWrapper {
        height: 100%;
        overflow: hidden;
        border-radius: 0.5rem;

        .priceBoxWrapper {
            height: 100%;
        }
    }

    .catalogPrice,
    .priceTitle {
        font-size: ${({ theme }) => theme.fontSizes.h6};
        color: ${({ theme }) => theme.colors.grey11};

        .catalogPriceLabel {
            font-size: ${({ theme }) => theme.fontSizes.h6};
        }
    }
    .priceBoxWrapper {
        background: ${({ theme }) => theme.colors.grey4};
        border-radius: ${rem(8)};
    }
    .price {
        .cashPrice,
        .monthlyPrice {
            font-size: ${({ theme }) => theme.fontSizes.h4};
        }
        .priceConjunction {
            span {
                font-size: ${({ theme }) => theme.fontSizes.h5};
                color: ${({ theme }) => theme.colors.grey11};
            }
        }
    }
    .currencyLabel__suffix--OTR,
    .currencyLabel__suffix--PM {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        text-transform: uppercase;
    }
`;
