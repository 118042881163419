import styled from 'styled-components';
import { PriceBoxStyled } from '../../PriceBoxStyled';

export const PriceBoxStyledOV = styled(PriceBoxStyled)`
    background: ${({ theme }) => theme.colors.grey10};
    height: unset !important;

    .catalogPrice,
    .priceTitle {
        color: ${({ theme }) => theme.colors.grey9};
    }
    .catalogPrice {
        font-size: ${({ theme }) => theme.fontSizes.h6};
    }
    .priceTitle {
        font-size: ${({ theme }) => theme.fontSizes.h7};
    }
    .priceBoxWrapper {
        background: ${({ theme }) => theme.colors.grey10};
    }
    .price {
        .price {
            display: grid;
            grid-template-rows: 0.3rem 1fr 4rem;
        }

        .cashPrice {
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-weight: 400 !important;
            color: ${({ theme }) => theme.colors.grey1};
            font-size: ${({ theme }) => theme.fontSizes.h4};
        }
        .monthlyPrice {
            font-size: ${({ theme }) => theme.fontSizes.h4};
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-weight: 400 !important;
        }
        .priceConjunction {
            span {
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-size: ${({ theme }) => theme.fontSizes.h6};
                color: ${({ theme }) => theme.colors.grey9};
            }
        }
        .infoIcon {
            svg {
                height: ${({ theme }) => theme.fontSizes.h5};
                g {
                    circle {
                        fill: none;
                    }
                }
                path {
                    fill: ${({ theme }) => theme.colors.grey1};
                }
            }
        }
    }
    .currencyLabel__suffix--OTR,
    .currencyLabel__suffix--PM {
        font-size: ${({ theme }) => theme.fontSizes.h7};
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        text-transform: uppercase;
    }
`;
