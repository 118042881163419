import styled from 'styled-components';
import PriceBoxTemplate from './PriceBoxTemplate';
import { rem } from 'polished';

export const PriceBoxStyled = styled(PriceBoxTemplate)`
    height: 100%;
    max-height: ${rem(244)};

    .currencyLabel__suffix--OTR,
    .currencyLabel__suffix--TTC,
    .currencyLabel__suffix--TTC-monthly {
        font-size: ${rem(8)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        text-transform: uppercase;
        margin: 0;
        padding: 0;
    }

    .currencyLabel__suffix--OTR,
    .currencyLabel__suffix--TTC {
        margin: 0 0 0 ${rem(3)};
    }

    .catalogPrice {
        min-height: ${rem(28)};
        background-color: ${({ theme }) => theme.colors.white};
        padding: ${rem(5)} 0;

        .catalogPriceLabel {
            font-weight: ${({ theme }) => theme.fontWeights.textLight};
            margin-right: ${rem(5)};
        }
        .catalogPriceAmount {
            font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
        }
    }

    .priceBoxWrapper {
        padding: ${rem(20)};

        .financeLabel {
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};

            .specialPriceLabel {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            }

            .priceLabel {
                margin-right: ${rem(3)};
            }
        }

        .price {
            .cashPrice {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                margin-top: ${rem(10)};

                & > .infoIcon {
                    display: inline-flex;
                    margin-left: ${rem(5)};

                    .vertical {
                        margin: 0;
                        height: 16px;
                    }
                }
            }

            .priceConjunction {
                margin-top: ${rem(7)};

                span {
                    font-weight: ${({ theme }) => theme.fontWeights.textLight};
                    line-height: ${({ theme }) => theme.lineHeights.textSmall};
                    text-transform: uppercase;
                }
            }

            .monthlyPrice {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                margin: ${rem(10)} 0;
            }
        }
    }
  
    .priceToolTipWrapper {
        display: inline-block;
    }
`;
