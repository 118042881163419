import { IOfferItem, IDefaultConfigurationData } from '../../../interfaces/IOfferItem';
import useTranslations from '@hooks/useTranslations';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { IConfigurableCar } from '../../../interfaces/Car';
import { PriceRounding } from '../../../types/priceRounding';

/**
 *
 * @description take offerItem as argument and returns keyWords as long string
 * function uses inside itself useTranslation hook
 *  Call it only from JSX or top level inside a component!!
 */

export const useGetPaymentKeywords = (
    deposit: number,
    duration: number,
    annualMileage: number,
    aprValue: number
): string => {
    const { t } = useTranslations();
    const { formatAmount } = useMoneyFormatter();

    return t('regularPaymentCondition', {
        deposit: formatAmount(deposit, false, t('price.pattern.currency')),
        duration,
        annualMileage,
        apr: aprValue,
    });
};

export const useGetOfferKeywords = (offer: IOfferItem): string => {
    const { t } = useTranslations();
    return [
        t('offerList.lowestPrice'),
        offer?.title,
        t('offerList.firstSeparator'),
        offer.exteriorColour.description,
        t('offerList.otherSeparator'),
        offer.interiorColour.title,
    ]
        .join(' ')
        .trim();
};

export const useGetModelKeywords = (
    item: IOfferItem | IConfigurableCar,
    defaultConfiguration: IDefaultConfigurationData,
    basePrice: number,
    monthlyPrice: number,
    modelTitleExtension: string = null,
    amountRounding?: PriceRounding
): string => {
    const { t } = useTranslations();
    const { formatAmount } = useMoneyFormatter();

    const modelTitle = `${
        modelTitleExtension
            ? (defaultConfiguration?.title || item?.title) + ' ' + modelTitleExtension
            : defaultConfiguration?.title || item?.title
    }`;

    return t(monthlyPrice ? 'regularModelCondition' : 'regularModelConditionCashOnly', {
        model: `${modelTitle}`,
        exteriorColour: defaultConfiguration ? defaultConfiguration.exteriorColourName : item.exteriorColour.title,
        basePrice: formatAmount(basePrice, true, t('price.pattern.currency')),
        monthlyPrice: formatAmount(
            monthlyPrice,
            Boolean(amountRounding && amountRounding !== PriceRounding.NO_ROUND),
            t('price.pattern.currency'),
            amountRounding
        ),
    });
};
