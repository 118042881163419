import styled from 'styled-components';
import { PriceBoxStyled } from '../PriceBoxStyled';
import { rem } from 'polished';

export const PriceBoxStyledAC = styled(PriceBoxStyled)`
    background: rgba(161, 154, 153, 0.1);
    border-radius: ${rem(12)};

    .catalogPrice,
    .priceTitle {
        font-size: ${({ theme }) => theme.fontSizes.h6};
        color: ${({ theme }) => theme.colors.grey11};
    }
    .price {
        .cashPrice,
        .monthlyPrice {
            font-size: ${({ theme }) => theme.fontSizes.h3};
        }
        .priceConjunction {
            span {
                font-size: ${({ theme }) => theme.fontSizes.h6};
                color: ${({ theme }) => theme.colors.grey11};
            }
        }
    }
    .currencyLabel__suffix--OTR,
    .currencyLabel__suffix--PM {
        font-size: ${({ theme }) => theme.fontSizes.h6};
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        text-transform: uppercase;
    }

    .priceWrap {
        &--priceLabel {
            color: ${({ theme }) => theme.colors.grey11};
            font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
        }

        &--priceLabelFrom {
            color: ${({ theme }) => theme.colors.grey11};
            padding-left: ${rem('6px')};
        }

        &--container {
        }

        &--price {
            font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
            font-size: ${({ theme }) => theme.fontSizes.h3};
        }

        &--priceBox {
            > div {
                :first-child,
                :last-child {
                    display: flex;
                    line-height: ${({ theme }) => theme.lineHeights.h4};
                }
            }
            .priceConjunction {
                color: ${({ theme }) => theme.colors.grey11};
                line-height: ${({ theme }) => theme.lineHeights.h4};
                margin: ${rem('6px')} 0;
            }
            .infoIcon {
                padding-left: ${rem('6px')};
            }
        }

        &--bonusLabel {
            color: ${({ theme }) => theme.colors.primary};
            font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
            font-size: ${({ theme }) => theme.fontSizes.h5};
            line-height: ${({ theme }) => theme.lineHeights.h5};
            margin: 0;
        }

        &--bonusLink {
            color: ${({ theme }) => theme.colors.primary};
            font-size: ${({ theme }) => theme.fontSizes.h5};
            line-height: ${({ theme }) => theme.lineHeights.h5};
            text-decoration: underline;
            cursor: pointer;
            margin-top: 0;
        }
    }
`;
