import React from 'react';
import { PriceBoxStyledAC } from './AC/PriceBoxStyled.AC';
import { PriceBoxStyled } from './PriceBoxStyled';
import { SC } from '../../../styles/theme';
import { BRAND, isMarketES, isMarketGB } from '../../../constants/main';
import { BrandTypes } from '../../../types/brands';
import { PriceBoxStyledDS } from './DS/PriceBoxStyled.DS';
import { PriceBoxStyledAP } from './AP/PriceBoxStyled.AP';
import { PriceBoxStyledOV } from './OV/PriceBoxStyled.OV';
import { PriceBoxStyledOV as PriceBoxStyledOV_GB } from './OV/GB/PriceBoxStyled.OV';
import { PriceBoxStyledOV as PriceBoxStyledOV_ES } from './OV/ES/PriceBoxStyled.OV';
import { PriceRounding } from '../../../types/priceRounding';

export interface PriceBoxProps extends SC {
    basePrice: number;
    monthlyPrice: number;
    catalogPrice?: number;
    calculateSummaryData?: () => void;
    isHomePage?: boolean;
    itemId?: string;
    inheritBackground?: boolean;
    isTrimPage?: boolean;
    apr?: number;
    hasMonthlyPrices: boolean;
    amountRounding?: PriceRounding;
    financeDetailView?: string;
    financeDetailText?: string;
    childRef?: {
        priceBoxRef?: React.Ref<HTMLDivElement>;
        priceBoxCatalogRef?: React.Ref<HTMLDivElement>;
        financeLabelContainerRef?: React.Ref<HTMLDivElement>;
        priceBoxContainerRef?: React.Ref<HTMLDivElement>;
    };
}

const PriceBox = (props: PriceBoxProps) => {
    if (BRAND === BrandTypes.OV) {
        if (isMarketGB) return <PriceBoxStyledOV_GB {...props} />;
        if (isMarketES) return <PriceBoxStyledOV_ES {...props} />;
        return <PriceBoxStyledOV {...props} />;
    }
    if (BRAND === BrandTypes.AC) return <PriceBoxStyledAC {...props} />;
    if (BRAND === BrandTypes.DS) return <PriceBoxStyledDS {...props} />;
    if (BRAND === BrandTypes.AP) return <PriceBoxStyledAP {...props} />;
    return <PriceBoxStyled {...props} />;
};

export default PriceBox;
