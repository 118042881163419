import React, { FC, memo } from 'react';
import { isBrandAC, isBrandAP, isMarketGB } from 'src/constants/main';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import useTranslations from '@hooks/useTranslations';
import Tooltip, { Effect } from '@components/GlobalTooltip';
import { Icons } from '@components/Icon';
import PriceSuffix from 'src/components/PriceSuffix';
import { PriceBoxProps } from './index';
import IconButton from '@components/IconButton';
import MonthlyPriceLabel, { AprSizes } from '@components/MonthlyPriceLabel';
import { usePriceBoxDetails } from '@hooks/usePriceDetails';

const PriceBoxTemplate: FC<PriceBoxProps> = memo(
    ({
        className,
        basePrice,
        monthlyPrice,
        catalogPrice,
        calculateSummaryData,
        itemId,
        apr,
        hasMonthlyPrices,
        amountRounding,
        childRef,
        financeDetailView,
        financeDetailText,
    }) => {
        const { t } = useTranslations();
        const { formatMoney } = useMoneyFormatter();
        const isCashDisabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_CASH_DISABLED);
        const {
            isCataloguePriceEqualToStorePrice,
            showFOIcon,
            showFOLink,
            isCataloguePriceFeatureSwitchEnabled,
            isFinancePriceAvailable,
        } = usePriceBoxDetails({
            catalogPrice,
            basePrice,
            financeDetailView,
            monthlyPrice,
        });

        return (
            <div className={`${className} priceBoxContainer`}>
                <div className="priceBoxContainerContentWrapper" ref={childRef?.priceBoxContainerRef}>
                    <div ref={childRef?.priceBoxCatalogRef} className="catalogPrice priceBoxCatalogRef">
                        {isCataloguePriceFeatureSwitchEnabled && !isCataloguePriceEqualToStorePrice && catalogPrice ? (
                            <>
                                <span className="catalogPriceLabel">{t('offerListItem.catalogPriceLabel')}</span>
                                <span className="catalogPriceAmount">{formatMoney(catalogPrice, true)}</span>
                            </>
                        ) : null}
                    </div>
                    <div ref={childRef?.priceBoxRef} className="priceBoxWrapper priceBoxWrapperRef">
                        <div className="price" data-testid={itemId ? `TESTING_TOTAL_PRICE_${itemId}` : ''}>
                            <div className="price">
                                <div className="priceTitle">
                                    <span>{t('offerListItem.standardPriceText')}</span>
                                </div>
                                <div className="cashPrice" data-testid={`TESTING_CASH_PRICE_${itemId}`}>
                                    <span>{formatMoney(basePrice, true)}</span>
                                    <PriceSuffix hideAsterisk />
                                    {showFOIcon && (
                                        <div
                                            className="vertical priceToolTipWrapper"
                                            data-tip={t('landingPage.solCard.priceInfoTooltip')}
                                            data-for="priceToolTip"
                                        >
                                            <IconButton
                                                className="infoIcon"
                                                icon={isBrandAC || isBrandAP ? Icons.InfoCircle : Icons.Info}
                                                width={16}
                                                height={16}
                                                onClick={calculateSummaryData}
                                                dataTestId={itemId ? `TESTING_INFOICON_${itemId}` : ''}
                                                altId="svgImgSeeFinanceDetails-1"
                                                altText={financeDetailText}
                                            />
                                        </div>
                                    )}
                                    <Tooltip
                                        id="priceToolTip"
                                        effect={Effect.solid}
                                        scrollHide={false}
                                        event="click"
                                        eventOff="mouseout"
                                        delayHide={300}
                                    />
                                </div>

                                {isFinancePriceAvailable && (
                                    <div>
                                        {!isCashDisabled && (
                                            <div className="priceConjunction">
                                                <span>{t('offerListItem.financePrice.conjunction')}</span>
                                            </div>
                                        )}

                                        <div className="monthlyPrice" data-testid={`TESTING_FINANCE_PRICE_${itemId}`}>
                                            <span>{formatMoney(monthlyPrice, false, '', amountRounding)}</span>
                                            <PriceSuffix isMonthlyPrice />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {hasMonthlyPrices && showFOLink && (
                            <MonthlyPriceLabel
                                size={AprSizes.LARGE}
                                aprValue={apr}
                                calculateSummaryData={calculateSummaryData}
                                financeDetailText={financeDetailText}
                                withPrimaryColor={isMarketGB}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
);
PriceBoxTemplate.displayName = 'PriceBoxTemplate';

export default PriceBoxTemplate;
