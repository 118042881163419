import styled from 'styled-components';
import { breakpoints } from '../../../../styles/theme';
import { PriceBoxStyled } from '../PriceBoxStyled';
import { rem } from 'polished';

export const PriceBoxStyledOV = styled(PriceBoxStyled)`
    .priceWrap {
        background: ${({ theme, inheritBackground }) => (inheritBackground ? 'inherit' : theme.colors.grey10)};
        min-width: ${rem('300px')};

        @media (max-width: ${breakpoints.xs}px) {
            min-width: auto;
        }

        &--cashPrice {
            text-align: right;
            font-family: ${({ theme }) => theme.fonts.fontBase};
            color: ${({ theme }) => theme.colors.grey1};
            font-size: ${({ theme }) => theme.fontSizes.h4};
            line-height: ${({ theme }) => theme.lineHeights.textSupMedium};
            font-weight: ${({ theme }) => theme.fontWeights.textMedium};

            .infoIcon {
                .vertical {
                    margin-right: 0;
                }
            }

            .priceWrap--price {
                font-size: ${({ theme }) => theme.fontSizes.h4};
                font-weight: ${({ theme }) => theme.fontWeights.textMedium};
                font-family: ${({ theme }) => theme.fonts.fontBase};
            }
        }
        &--bonusLabel {
            font-size: ${({ theme }) => theme.fontSizes.textBase};
            font-weight: ${({ theme }) => theme.fontWeights.textLight};
            font-family: ${({ theme }) => theme.fonts.fontBase};
            font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
            color: ${({ theme }) => theme.colors.black2};
        }

        &--priceLabel {
            font-size: ${({ theme }) => theme.fontSizes.h7};
            font-family: ${({ theme }) => theme.fonts.fontSecondary};
            color: ${({ theme }) => theme.colors.grey1};
            line-height: ${({ theme }) => theme.lineHeights.textSupMedium};
            top: ${rem('-18px')};
        }

        &--label {
            font-size: ${({ theme }) => theme.fontSizes.h7};
            font-family: ${({ theme }) => theme.fonts.fontSecondary};
            color: ${({ theme }) => theme.colors.grey1};
        }

        &--bonusLink {
            font-size: ${({ theme }) => theme.fontSizes.h5};
            font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
            text-transform: lowercase;
            color: ${({ theme }) => theme.colors.black2};
        }

        &--monthlyPrice {
            text-align: left;
            font-family: ${({ theme }) => theme.fonts.fontBase};
            color: ${({ theme }) => theme.colors.black};
            font-size: ${({ theme }) => theme.fontSizes.h4};
            line-height: ${({ theme }) => theme.lineHeights.textSupMedium};
            font-weight: ${({ theme }) => theme.fontWeights.textMedium};

            .priceWrap--price {
                font-size: ${({ theme }) => theme.fontSizes.h4};
                font-weight: 700;
                font-family: ${({ theme }) => theme.fonts.fontBase};
                color: ${({ theme }) => theme.colors.black};
            }

            .currencyLabel__suffix--PM {
                font-size: ${({ theme }) => theme.fontSizes.h4};
                font-family: ${({ theme }) => theme.fonts.fontBase};
                font-weight: 700;
            }
        }
    }
    .currencyLabel__suffix--OTR {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        font-weight: ${({ theme }) => theme.fontWeights.textMedium};
    }

    .separator-line {
        display: none;
        border: ${rem('1px')} solid ${({ theme }) => theme.colors.grey11};
    }

    .priceWrap--cashPrice {
        visibility: hidden;
    }
`;
